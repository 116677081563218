import React, { Component } from "react";
import GRC from '../images/GRC_main-spirit-mark_rgb-gray-border-.png';
import NavigationBar from "./NavigationBar";

export default class Education extends Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <div className="education-page">
          <h2 className="education-title">Education</h2>
          <p className="education-info">B.a.S. Information Technology: Cybersecurity & Networking</p>
          <div>
            <img src={GRC} alt="GRC" className="images-grc" />
          </div>
        </div>
      </div>
    );
  }
}