import React, { Component } from "react";
import './about.css';
import NavigationBar from "./NavigationBar";
import BlueLogo from "../images/bologo.png"

export default class Experience extends Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <div className="experience-page">
          <h2 className="experience-title">Experience</h2>
          <div>
            <p className="experience-info">Infrastructure Engineer</p>
            <p className="experience-info2">Data Sciences & Analytics</p>
            <img src={BlueLogo} alt="Blue Logo" className="experience-img"></img>
          </div>
        </div>
      </div>
    );
  }
}