import React, { Component } from "react";
import './about.css';
import PowerShell from '../images/powershell.png';
import API from '../images/api.png';
import ReactLogo from '../images/React-icon.svg.png';
import HTML from '../images/html.png';
import CSS from '../images/css.png';
import Linux from '../images/linux.png';
import NavigationBar from "./NavigationBar";

export default class About extends Component {
    render() {
        return  (
            <div>
                <NavigationBar />
                <div className="about-card">
                    <h4 className="about-card-title">About Me</h4>
                    <div className="about-additional-info">
                        <p className="about-me-text">
                        I am a seasoned IT professional with a strong background in Windows and Linux systems, scripting, and automation, complemented by a degree in IT: Cybersecurity & Networking. Currently contributing to the Data Sciences & Analytics team at Blue Origin, I specialize in process automation. Alongside professional roles, I've successfully developed a feature-rich React JS website. Proficient in PowerShell, React JS, HTML/CSS, API integrations, Linux, Active Directory, Jira, and Confluence, my ambition is to transition into Data Engineering. I am enthusiastic about applying my skills to make meaningful contributions to a dynamic team and can be reached at mcginty.ryan127@gmail.com for potential collaborations.
                        </p>
                    </div>
                    <div className="image-container">
                        <div className="image-row">
                            <img src={PowerShell} id="ps" alt="powershell" className="images" />
                            <img src={ReactLogo} id="react" alt="react" className="images" />
                            <img src={API} id="api" alt="alt" className="images"/>
                        </div>
                        <div className="image-row">
                            <img src={HTML} id="html" alt="HTML" className="images" />
                            <img src={CSS} id="css" alt="CSS" className="images" />
                            <img src={Linux} id="linux" alt="Linux" className="images" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}