import React from 'react';
import { Link } from 'react-router-dom';
import './navigationbar.css';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function NavigationBar() {
  return (
    <div className='navigation-bar'>
      <div className='navigation-buttons'>
        <Link to="/"><button className='navigation-button'><FontAwesomeIcon icon={faHome}></FontAwesomeIcon></button></Link>
        <Link to="/about"><button className='navigation-button'>About Me</button></Link>
        <Link to="/education"><button className='navigation-button'>Education</button></Link>
        <Link to="/experience"><button className='navigation-button'>Experience</button></Link>
      </div>
    </div>
  );
}

export default NavigationBar;
